import React from 'react';
import styled from '@emotion/styled';
import {colors} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';
import {
  ViewportSize,
  combineMediaQueries,
  contentHSpace,
  maxBespokeContentWidth,
  mqAt,
} from '../../utils/breakpoints';

const backgroundColor = colors.soap100;
const curveHeight = 200;
const paddingTop = 80;
const shimHeight = 1;

/**
 * The curve portion of the background (above RectangleBG).
 */
const CurveBG = styled(Box)({
  height: `${curveHeight}px`,
  overflow: 'hidden',
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    height: '185vw',
    width: '185vw',
    backgroundColor: backgroundColor,
    borderRadius: '100%',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%)',
    zIndex: -1,
    '@media (min-width: 1441px)': {
      height: '250vw',
      width: '250vw',
    },
  },
});

/**
 * The rectangle portion of the background (below CurveBG).
 */
const RectangleBG = styled(Box)({
  background: backgroundColor,
  // This shim works in conjunction with the negative bottom margin on the
  // Content to remove the empty space left behind after translating the
  // Content upwards.
  '&:after': {
    content: '""',
    display: 'block',
    height: `${shimHeight}px`,
  },
});

interface ContentProps extends Pick<BespokeCurvedSectionProps, 'centered'> {
  children: React.ReactNode;
}

const getContentMediaQuery = (size: ViewportSize) => {
  const hPadding = contentHSpace[size];

  return {
    [mqAt(size)]: {
      maxWidth: maxBespokeContentWidth + 2 * hPadding,
      paddingRight: hPadding,
      paddingLeft: hPadding,
    },
  };
};

/**
 * The actual content of the BespokeCurvedSection. The CSS here is funky.
 * In order to get the content to occupy part of the curve, we translate it
 * upwards by the height of the curve minus the amount of padding we want at
 * the top. We apply an equivalent negative bottom margin to remove the empty
 * space left behind after the translation (we need the &:after shim in
 * RectangleBG for this technique to work properly).
 */
const Content = ({centered, children, ...elemProps}: ContentProps) => {
  const styles = {
    transform: `translateY(-${curveHeight - paddingTop}px)`,
    ...combineMediaQueries(getContentMediaQuery),
  };

  return (
    // No need to set paddingTop since that spacing is already being handled by
    // the translateY above
    <Box
      marginBottom={`-${curveHeight - paddingTop + shimHeight}px`}
      marginX={centered ? 'auto' : undefined}
      paddingBottom="xxxl"
      css={styles}
      {...elemProps}
    >
      {children}
    </Box>
  );
};

export interface BespokeCurvedSectionProps {
  centered?: boolean;
  children: React.ReactNode;
}

/**
 * BespokeCurvedSection is a container with a background color and a curved
 * top.
 */
export const BespokeCurvedSection = ({
  centered = true,
  children,
  ...elemProps
}: BespokeCurvedSectionProps) => (
  <Box marginTop={120} {...elemProps}>
    <CurveBG />
    <RectangleBG>
      <Content centered={centered}>{children}</Content>
    </RectangleBG>
  </Box>
);
