import React from 'react';
import {withPrefix} from 'gatsby';
import {Box} from '@workday/canvas-kit-react/layout';
import {space} from '@workday/canvas-kit-react/tokens';
import {viewportRanges} from '../../utils/breakpoints';

export interface AnnouncementBannerProps {
  largeBanner: string;
  smallBanner: string;
  alt: string;
  link: string;
}

export const AnnouncementBanner = ({
  largeBanner,
  smallBanner,
  alt,
  link,
  ...elemProps
}: AnnouncementBannerProps) => {
  return (
    <Box padding="xxs">
      <span style={{width: '100%', maxWidth: '100vw'}}>
        <a href={withPrefix(link)}>
          <Box borderRadius={space.s} overflow="hidden" width="100%" cursor="pointer">
            <picture style={{display: 'block', width: '100%'}}>
              <source
                media={`(min-width: ${viewportRanges.l[0]}px)`}
                srcSet={largeBanner}
                {...elemProps}
              />
              <img
                src={smallBanner}
                alt={alt}
                {...elemProps}
                style={{display: 'block', width: '100%'}}
              />
            </picture>
          </Box>
        </a>
      </span>
    </Box>
  );
};
